import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { ISkill } from "../../models/skill";
import { signal } from "@angular/core";

export const SkillsPageActions = createActionGroup({
    source: 'Skill Page',
    events: {
        'Load Skills': emptyProps(),
        'Add Skill': props<{skill: ISkill}>(),
        'Add Skills': props<{skills: ISkill[]}>(),
        'Update Skill': props<{skill: ISkill}>(),
        'Delete Skill': props<{skillId: string}>(),
    }
}
)
export const SkillsAPIActions = createActionGroup({
    source: 'Skill API ',
    events:{
        'Skills Loaded Success' : props<{skills:ISkill[]}>(),
        'Skills Loaded Fail': props<{ message: string }>(),
        'Skill Added Success': props<{skill: ISkill}>(),
        'Skill Added Fail': props<{message: string}>(),
        'Skills Added Success': props<{skills: ISkill[]}>(),
        'Skills Added Fail': props<{message: string}>(),
        'Skill Updated Success': props<{skill:ISkill}>(),
        'Skill Updated Failed': props<{message:string}>(),
        'Skill Deleted Success': props<{skillId:string}>(),
        'Skill Deleted Fail': props<{message:string}>()
    }
})
